import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import PrivacyNoticeBanner from './PrivacyNoticeBanner';
import ContainerizedBackground from './ContainerizedBackground';
import HomePage from './PageComponents/HomePage';
import SearchPage from './PageComponents/SearchPage';
import ContentPage from './PageComponents/ContentPage';
import ErrorPage from './PageComponents/ErrorPage';
import NavigationBar from './NavigationBar';
import Footer from './DumbComponents/Footer';

import './App.css';


function App() {

	// prepare for state toggling dark mode and light mode.

	return (
	<React.Fragment>
		<ContainerizedBackground />
		{/* <PrivacyNoticeBanner /> */}
		<NavigationBar />
		<Switch>
			<Route path="/posts/:postId"><ContentPage  contentType="post"/></Route>
			<Route path="/projects/:projectId"><ContentPage contentType="project"/></Route>
			<Route path="/notebox/:noteId"><ContentPage contentType="notebox"/></Route>
			<Route path="/search"><SearchPage /></Route>
			<Route exact path="/"><HomePage /></Route>
			<Route><ErrorPage /></Route>
		</Switch>
		<Footer />
	</React.Fragment>
	);
}

export default App;
