function useLocalStorage(propertyKey, defaultValue = ''){

	let propertyValue = localStorage.getItem(propertyKey) || defaultValue;

	function setProperty(newValue){

		localStorage.setItem(propertyKey, newValue);
	
	}

	function resetProperty(){

		localStorage.setItem(propertyKey, defaultValue);

	}

	return [propertyValue, setProperty, resetProperty];

}

export default useLocalStorage;