import { useState } from 'react';

function useControlledForm(INITIAL_FORM_STATE){

	const [formState, setFormState] = useState(INITIAL_FORM_STATE);

	function updateFormState({name, value}){

		const formStateBuffer = {
			...formState,
			[name]: value
		}

		setFormState((formState) => formStateBuffer);
		// console.log(formStateBuffer)
		// return formStateBuffer;

	}

	return [formState, updateFormState];

}

export default useControlledForm;