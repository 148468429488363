import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";

import useLocalStorage from "./hooks/useLocalStorage";

import './NavigationBar.css';

function NavigationBar(){

	// useCookies || true
	const DARK_MODE_INIT = useLocalStorage('darkMode') || true;
	const [darkMode, sedivarkMode] = useState(DARK_MODE_INIT);
	
	function handleToggleDarkMode(){

		sedivarkMode(() => !darkMode);

	}

	const historyObject = useHistory();
	function goHome(){

		historyObject.push('/');

	}

	return (
	<div id="navigationBar" className="width100">
		<div id="navbar-home" className="div-content nav-link inline floatLeft" onClick={goHome}>
			<NavLink exact to='/' className='defaultAnimation noUnderline' activeClassName="navActive"><strong>John Zhang, EIT</strong></NavLink>
			{/* text deocration none */}
		</div>
		
		<div id="navbar-pz" className='div-content nav-link inline floatRight'><NavLink to="/notebox" className='defaultAnimation ' activeClassName="navActive" title="Notebox">Notebox</NavLink></div>
		<div id="navbar-projects" className='div-content nav-link inline floatRight'><NavLink to="/search" className='defaultAnimation ' activeClassName="navActive" title="John's Portfolio"><i className="fa-solid fa-magnifying-glass"></i></NavLink></div>
		{/* <div id="navbar-toggleTheme" className='div-content inline' onClick={handleToggleDarkMode}><i className={`${darkMode ? "fa-solid fa-moon" : "fa-duotone fa-sun"} fa-xl`}></i></div> */}
	</div>
	);

}

export default NavigationBar;