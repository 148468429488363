import React from 'react';
import { Link } from "react-router-dom";

import './ContentCard.css'

function ContentCard({title, description, tags, datePublished}){

	const truncatedTitle = title.length > 20 ? title.substring(0, 18).concat('...') : title;
	const truncatedDescription = description.length > 100 ? description.substring(0, 98).concat('...') : description;

	return (
	<div className="contentCard defaultAnimation">
		<h3 className='contentCardHeader'>{truncatedTitle}</h3>	
		<p>{truncatedDescription}</p>
		<p className='contnetCardTagContainer'>{tags.map((element, index) => (
			<React.Fragment key={element}>
				<Link className='contentCardTag defaultAnimation' to={`/search?query=${element.toLowerCase()}`}>
					{element}
				</Link>
				{index === tags.length - 1 ? undefined : " "}
			</React.Fragment>
			))}
		</p>
		<p className="contentDatePublished">Date Published: {datePublished}</p>
	</div>
	);

}

export default ContentCard;