function ContainerizedBackground({ darkModeEnabled }){

	// state to togglelightmode

	return (
	<div id='bgContainer'>
		<div id='bgBackdrop'></div>
		<div id='bgTexture'></div>
		<video id='bgVideo' autoPlay muted playsInline preload="metadata" poster='/yjzhang_bg_static.jpg' loop>
			<source src='/yjzhang_bg.mp4' type="video/mp4" />
			<img src='/yjzhang_bg_static.jpg' alt="Fallback <img> if <video> is not supported."></img>
		</video>
		<img id='bgVideoFallback' src='/yjzhang_bg_static.jpg' ></img>
		
		{/* add js ability to play and pause video */}

		{/* React-Chrome Autoplay Bug: https://github.com/facebook/react/issues/10389#issuecomment-605689475 */}

	</div>
	);

}

ContainerizedBackground.defaultProps = {
	darkModeEnabled: undefined
}
	// stopgap prevent error

export default ContainerizedBackground;