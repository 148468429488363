import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContentCard from '../DumbComponents/ContentCard';

import APIHelper from '../api/APIHelper';
import useControlledForm from '../hooks/useControlledForm';

function SearchPage(){

	const { search } = useLocation();

	// useEffect(() => {

	// }, [])

	// Search Params
	function parseSearchQuery(query){

		if(query.length <= 1)
			return {};

		let queryObj = query.substring(1, query.length).split('&').reduce((queryObject, element) => {

			let elementPair = element.split('=');
			queryObject[elementPair[0]] = decodeURIComponent("" || elementPair[1]);
			return queryObject;

		}, {});

		return queryObj;

	}

	const searchObject = parseSearchQuery(search);

	let INIT_SEARCH_FORM = {
		searchQuery: searchObject.query || '',
		searchContent: searchObject.content || 'searchAll',
		/*
			dateStart:
			dateEnd:
			searchQuery: tags, title
			type: [all, projects, articles]

		*/
	}

	const [formState, setFormState] = useControlledForm(INIT_SEARCH_FORM);
	const [searchResults, setSearchResults] = useState([]);

	// API Fetch
	useEffect(() => {

		function fetchContent(request){

			
		}



	}, [/* disabled for now */]);


	function formChangeHandler(evt){
		
		const {name, value} = evt.target;

		setFormState({name, value});

	}

	const targetContent = formState.searchContent === 'searchAll' ? 'Contents' : formState.searchContent === 'searchProjects' ? 'Projects' : 'Note Box';

	return (
	<div id="container-Search" className="fullPagePageContent">
		{/* <p>{formState.searchQuery}, {formState.searchContent}</p> */}
		<form>

			<div className="input-group">

				<input name="searchQuery"
					type="text"
					id="searchQuery"
					className="form-control"
					placeholder={`Search ${targetContent}...`}
					value={formState.searchQuery}
					onChange={formChangeHandler} />
				<label className="visually-hidden" htmlFor="searchQuery">Search Query</label>
					
				<input name="searchContent"
					type="radio"
					id="searchAll"
					className="btn-check"
					value="searchAll"
					checked={formState.searchContent==="searchAll"}
					onChange={formChangeHandler} />
				<label className="btn btn-success defaultAnimation" htmlFor="searchAll" title="Search All"><i className="fa-duotone fa-box-archive"></i></label>

				<input name="searchContent"
					type="radio"
					id="searchProjects"
					className="btn-check"
					value="searchProjects"
					checked={formState.searchContent==="searchProjects"}
					onChange={formChangeHandler} />
				<label className="btn btn-success defaultAnimation" htmlFor="searchProjects" title="Search Projects"><i className="fa-duotone fa-file-zipper"></i></label>

				<input name="searchContent"
					type="radio"
					id="searchArticles"
					className="btn-check"
					value="searchArticles"
					checked={formState.searchContent==="searchArticles"}
					onChange={formChangeHandler} />
				<label className="btn btn-success defaultAnimation" htmlFor="searchArticles" title="Search Articles"><i className="fa-duotone fa-file-lines"></i></label>

			</div>
			<p className='smallText'></p>
			<div className='input-group'>

				<span className="input-group-text">Date Range (Start)</span>
				<input name="dateStart" 
					type="date"
					id="dateStart"
					className="form-control"
					value={formState.dateStart}
					min="2023-04-10"
					onChange={formChangeHandler} />

				<span className="input-group-text">Date Range (End)</span>
				<input name="dateEnd" 
					type="date"
					id="dateEnd"
					className="form-control"
					value={formState.dateEnd}
					min="2023-04-10"
					onChange={formChangeHandler} />

			</div>
			<p className='smallText'></p>

		</form>
		<div>
			<p>Search Results for "{formState.searchQuery.length > 0 ? formState.searchQuery : undefined}"{targetContent === 'Contents' ? undefined : ` in ${targetContent}`}...</p>
			<ContentCard key={'filler'}
				title={'Coming Soon'}
				description={'Searchable database function coming soon in a few months (low priority): Setting up backend'}
				tags={['pern', 'postgresql', 'express', 'react', 'nodejs', 'js']}
				datePublished="2023-04-17" />
			{/* {} */}
		</div>

	</div>
	);

}

export default SearchPage;