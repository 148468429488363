import React from "react";
import { Link } from "react-router-dom";

// import './Footer.css';

const Footer = ({ skillTags }) => (
	<div id='footer'><table className="width100"><tbody>
		<tr>
			<td className="textAlignLeft footerText">This portfolio site was created with the following; see my other projects created with:
				{skillTags.map((element, index) => (
				<React.Fragment key={index}>
					<p className="inline">&nbsp;&nbsp;</p>
					<Link key={element.skillName}
						className="inline footer-tagSearch defaultAnimation"
						title={`See more projects created with ${element.skillName}`}
						to={`/search?content=searchProjects&query=${element.skillName}`}>
								{element.tagIcon ? <i className={element.tagIcon}></i> : <img className="inline footer-svg" alt={element.skillName} src={element.tagSVG} />}
					</Link>
				</React.Fragment>
				))}
			</td>
			<td className="textAlignCenter footerText">Page Compatibility Notes: <i className="fa-brands fa-js"></i> Requried (One Page), <a className="defaultAnimation" href="https://caniuse.com/?search=transition" target="_blank" rel='norefferer noopener'>96.94% (as developed, global all users)</a></td>
			<td className="textAlignRight footerText">&copy; John Zhang 2016 - {new Date().getFullYear()}</td>
		</tr>
	</tbody></table></div>
);

Footer.defaultProps = {
	skillTags: [
		{skillName: 'react', tagIcon: 'fa-brands fa-react'},
		{skillName: 'node', tagIcon: 'fa-brands fa-node-js'},
		{skillName: 'html', tagIcon: 'fa-brands fa-html5'},
		{skillName: 'css', tagIcon: 'fa-brands fa-css3'},
		{skillName: 'js', tagIcon: 'fa-brands fa-js'},
		{skillName: 'bootstrap', tagIcon: 'fa-brands fa-bootstrap'},
		{skillName: 'adobeAfterEffects', tagSVG: '/logo_adobeAfterEffects.svg'},
		{skillName: 'adobeIllustrator', tagSVG: '/logo_adobeIllustrator.svg'},
		{skillName: 'adobePhotoshop', tagSVG: '/logo_adobePhotoshop.svg'}
	]
}

export default Footer;