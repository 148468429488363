import { Link } from "react-router-dom";

const ErrorPage = () => (
<div id="container-Error" className="fullPageSingleContent">
	<div>
		<h1>
		<i className="fa-solid fa-triangle-exclamation"></i> 404. Resource not found.</h1>
		<p>Return <Link className="defaultAnimation internalLink" to="/">home</Link>.</p>
	</div>
</div>
);

export default ErrorPage;